.what
	padding: 100px 0px
	background: linear-gradient(0deg, rgba(33,33,33,0.9), rgba(33,33,33,0.9)) 0% 0%/ cover
	&__content
		margin-top: 60px
	&__row
		display: flex
		flex-wrap: wrap
		margin: -10px
	&__col
		display: flex
		flex-direction: column
		flex-basis: calc((100% / 6 ) - 20px)
		margin: 10px
		justify-content: center
		align-items: center
		gap: 24px
		text-align: center
		&:not(:last-child)
			border-right: 1px solid $brand
		& svg
			width: 95px
			height: 95px
		& p.text
			padding: 0 20px
			font-family: 'JetBrainsMono'
			font-weight: 300

	+media-phone-s()
		padding: 50px 0px
		& h2.title
			font-size: 26px
		&__row
			flex-direction: column
		&__col
			flex-basis: calc((100% / 1) - 20px)
			&:not(:last-child)
				border-bottom: 1px solid $brand
				border-right: none
				padding-bottom: 30px