html.page-preloader
	height: 100vh
	overflow: hidden
	position: relative
	&:after
		content: ''
		width: 100%
		height: 100%
		top: 0px
		left: 0px
		z-index: 9999
		position: fixed
		background-color: #fff
	&:before
		content: ''
		top: 0px
		left: 0px
		right: 0px
		bottom: 0px
		margin: auto
		z-index: 99999
		position: absolute
		width: 60px
		height: 60px
		border-radius: 100px
		background-color: var(--color-brand)
		animation: animePagePreloader 1s ease alternate infinite
	

@keyframes animePagePreloader
	from
		transform: scale(1)
	
	to
		transform: scale(5)		
			