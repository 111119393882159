button, .btn
	cursor: pointer
	font-size: 16px
	font-weight: 400
	font-family: $font-family
	text-decoration: none
	background: linear-gradient(90deg, rgba(253,192,107,0.7959558823529411) 0%, rgba(23,25,34,0.5690651260504201) 100%)
	transition: all $transition-primary
	font-family: 'JetBrainsMono' 
	&:hover
		color: #171922
		background: $brand
	&:active
		btn-active()		

button
	padding: 0px
	-webkit-appearance: none

.btn	
	color: var(--color-brand)
	padding: 13px 25px 13px 30px


// colors
.btn
	&--brand
		position relative
		color: $brand
		border: none
		border-radius: 0 30px 30px 0
		&:before
			content: ''
			width: 90px
			height: 90px
			display: block
			position: absolute
			top: -22px
			left: -50px
			background: inline('btn.svg') no-repeat center
			background-size: contain
