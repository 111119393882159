.example
	padding: 150px 0px
	min-height: 1200px
	background url('../img/background/Rectangle.svg') no-repeat, linear-gradient(0deg, rgba(33, 33, 33, 0.9), rgba(33, 33, 33, 0.9)) 0% 0% / cover
	background-position: center
	&__header
		display flex
		width: 100%
		align-items: center
		& h2
			flex-basis: 400px
		& p
			flex-basis: calc(100% - 400px)
			font-size: 16px
			font-weight: 300
			font-family: 'JetBrainsMono'
			color: $gray
	&__body
		margin-top: 100px
		&-link
			display: flex
			gap: 24px
			& a
				color: $gray
				font-size: 16px
				text-decoration: underline
				font-family: 'JetBrainsMono'
			& span
				color: $gray
				font-size: 16px
				font-family: 'JetBrainsMono'
		&-title
			margin-top: 40px
			& p
				color: $brand
				font-size: 20px
				font-family: 'JetBrainsMono'
				position relative
				width: fit-content
				&:after
					content: ''
					width: 100%
					height: 2px
					background: $brand
					display: block
					position: absolute
		&-descr
			display: flex
			gap: 80px
			margin-top: 60px
			& p
				&.title
					font-family: 'JetBrainsMono'
					font-size: 20px
					font-weight: 700
					position: relative
					&:before
						content: ''
						display: block
						position absolute
						width: 38px
						height: 50px
						left: -24px
						top: -12px
						background: inline('task.svg') no-repeat center
	&__left
		flex-basis: 100px
		font-family: 'JetBrainsMono'
	&__center
		flex-basis: calc((100% / 2) - 100px)
		font-family: 'JetBrainsMono'
		& p
			font-size: 14px
			font-weight: 300
			&.secondary
				margin-top: 32px
	&__right
		flex-basis: calc((100% / 2) - 100px)
		font-family: 'JetBrainsMono'
		& p
			&.text
				font-size: 14px
				font-weight: 300
				+ .text
					margin: 27px 0
	&__ul
		& li
			font-weight: 300
			font-size: 14px
	+media-table-m()
		background url('../img/background/case_bcg-mob.svg') no-repeat, linear-gradient(0deg, rgba(33, 33, 33, 0.9), rgba(33, 33, 33, 0.9)) 0% 0% / cover
		background-size: cover
		padding: 100px 0px
		& h2.title
			font-size: 26px
			line-height: 80%
			flex-basis: auto
		&__header
			flex-direction: column
			align-items: flex-start
			& p
				flex-basis: auto
		&__body
			margin-top: 0px
			&-link
				flex-direction: column
				gap: 10px
			&-descr
				flex-direction: column
				gap: 20px
		&__ul
			display: flex
			flex-wrap: wrap
			& li
				padding: 10px
		&__left
			order: 3