.feedback
	background  linear-gradient(0deg, rgba(33, 33, 33, 0.9), rgba(33, 33, 33, 0.9)) 0% 0% / cover, url('../img/background/background.svg')
	padding: 100px 0
	& h2.title
		font-weight: 800
		font-size: 38px
	&__container
		&.mt-60px
			margin-top: -60px
	&__row
		display: flex
		align-items: center
	&__col
		flex-basis: 50%
		&.--left
			& img
				width: 100%
				height: auto
		&.--right
			&.--mt-150
				margin-top: -150px
			& p.announce
				font-family: 'JetBrainsMono'
				font-size: 22px
				font-weight: 300
				line-height: 24.2px
				margin-bottom: 30px
				color: $gray
	&__form
		display: flex
		gap: 40px
		flex-direction: column
		&-top
			display flex
			gap: 40px
			& label
				width: 100%
		&-bot
			display: flex
			flex-direction: column
			gap: 20px
			align-items: flex-end
			& textarea
				width: 100%
			& button
				width: fit-content
		&-label
			display: flex
			flex-direction: column
			align-self: flex-start
			gap: 5px
			
			color: #FFFFFF
			font-size: 20px
			font-weight: 300
			font-family: 'JetBrainsMono'

		& input.form-field,textarea
			border-radius: 10px
			border: 0.5px solid $brand
			background: rgba(29, 31, 41, 0.80)
			color: #FFF
			font-family:'JetBrainsMono';
	&__list //это относится к странице Contacts!
		display: flex
		flex-direction: column
		align-items: baseline
		& li
			margin-bottom: 40px
		& a
			display: flex
			flex-direction: row-reverse
			gap: 12px
			align-items: center
			justify-content: center
			text-decoration: none
			font-family: "JetBrainsMono"
			font-size:20px
			& svg
				width: 20px
				height: 20px	
	+media-table-m()
		padding: 50px 0px
		& h2.title
			font-size: 24px
		&__row
			flex-direction: column
		&__form
			&-top
				flex-direction: column
			&-bot
				& button
					width: 90%
		&__col
			&.--right
				& p.announce
					font-size: 16px
				&.--mt-150
					margin-top: 0px
		&__container
			&.mt-60px
				margin-top:  60px