.clients
	padding-top: 100px
	background:inline('ellipse_cli.svg') no-repeat, linear-gradient(0deg, rgba(33,33,33,0.9), rgba(33,33,33,0.9)) 0% 0%/ cover
	background-position: right bottom
	background-size: contain
	& h2.title
		font-weight: 800
		font-size: 38px
	&__container
		margin-top: 115px
		height: 300px
		position relative
		z-index 1
	&__pic
		transition: 0.5s linear
		display: flex
		justify-content: center
		align-items: center
		height: 100%
		padding: 0 10px
		min-height: 185px
		&:not(:hover)
			filter: grayscale(1)
			opacity: 0.3
			& img
				backdrop-filter: blur(5px)
		& img
			width: 300px
			height: 50px
	+media-phone-s()
		& h2.title
			font-size: 24px
		&__container
			height: 350px
		&__pic
			&:not(:hover)
				opacity: 1
				filter: none
				& img
					backdrop-filter: none

.clients .splide__slide
	filter: blur(3px)
	&:hover
		position relative
		background: inline('podloz2.svg') no-repeat
		background-size: contain
		min-height: 185px
		filter: blur(0px)
		& img
			height: 55px
	+media-phone-s()
		background: inline('podloz2.svg') no-repeat
		background-size: contain
		filter: blur(0px)


.tooltip
	&-content
		position: absolute
		left: -500px
		top: 55px
	+media-phone-s()
		&-content
			left: 95px
			top: 250px
.back
	width: 252px
	height: auto
	position absolute
	& p
		font-size: 14px
		font-weight: 300
		font-family: 'JetBrainsMono'
		margin: 0px

	&-after
		position relative
		&:before
			content: ''
			width: 390.25px
			height: 184.5px
			background: inline('tooltip.svg') no-repeat
			display: block
			position absolute
			top: -60px
			left: -30px
	+media-phone-s()
		&-after
			&:before
				background: inline('tooltip_mob.svg') no-repeat
				height: 250px
				top: -64px
				left: -25px
