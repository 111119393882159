h1, h2, h3, h4, h5, h6, .title
	font-size: 26px
	line-height: normal
	margin: 0px 0px 30px
	font-family: 'JetBrainsMono'
	position: relative
	&.title-svg
		font-size: 32px
		&:before
			content: ''
			background: inline('title.svg') no-repeat
			background-size: cover
			display: block
			position absolute
			top: -25px
			left: -30px
			width: 100px
			height: 100px
	+media-phone-s()
		&.title-svg
			&:before
				top: -35px
				left: -20px


p, ul, ol
	margin: 0px
	&:not([class])
		margin: 20px 0px

ul, ol
	padding-left: 0px		
	list-style-type: none
	&:not([class])
		li
			position: relative
			padding-left: 15px
			& + li
				margin: 10px 0px 0px

ul
	&:not([class])
		li:before
			content: ''
			top: 6px
			left: 0px
			position: absolute
			width: 8px
			height: 8px
			display: block
			border-radius: 100px
			background-color: var(--color-brand)

ol
	counter-reset: num 
	li:before
		content: counter(num)
		counter-increment: num
		top: 0px
		left: 0px
		position: absolute
		display: block
		color: var(--color-brand)
		font-weight: bold	

a
	text-decoration: none
	transition: all $transition-primary
	&:not([class])
		color: var(--blue-primary)
		text-decoration: underline
		&:hover
			text-decoration: none	
	&:active
		btn-active()

.table-wrapper
	width: 100%
	margin: 20px 0px
	overflow-x: auto
	scroll-shadow-x(#fff)

table
	width: 100%
	min-width: 768px
	border-collapse: collapse

td, th
	padding: 10px
	border: 1px solid var(--black-primary)

th
	text-align: left
	background-color: var(--gray-primary)

mark
	color: #fff
	background-color: var(--color-brand)
		
hr
	margin: 30px 0px
	border: 2px solid var(--color-brand)