*
	box-sizing: border-box
html
	scroll-behavior: smooth

body 
	display: flex
	flex-direction: column
	margin: 0px
	min-height: 100vh
	color: #FFFFFF
	font-size: 16px
	line-height: 130%
	font-family: $font-family
	background: #202230
	box-shadow: 0px 10px 40px 0px #00000066

.scroll-off
	overflow: hidden

.wrapper
	display: flex
	flex: 1 0 auto

.container
	width: 100%
	max-width: 1500px
	margin-left: auto
	margin-right: auto
	+media-desktop-m(resolution = $lg_l)
		padding: 0 150px
	+media-table-m()
		padding: 0 40px
.main
	width: 100%
