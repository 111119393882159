.footer
	display: flex
	flex-direction: column
	background  linear-gradient(0deg, rgba(33, 33, 33, 0.9), rgba(33, 33, 33, 0.9)) 0% 0% / cover
	padding-bottom: 50px
	&__top
		background: url('../img/background/footer.svg') no-repeat
		min-height: 190px
	&__main
		margin-top: 100px
	&__row
		display: flex
		justify-content: space-between
		margin: -10px
	&__col
		margin: 10px
		&:not(.--center)
			flex-basis: 360px
		&.--center
			flex-grow: 1
		&.--left
			& svg
				width: 300px
				height: 100px
	&-menu
		display: flex
		margin: -10px
		justify-content: center
		&__col
			margin: 10px
			flex-basis: 30%
	&-nav
		margin: -5px
		display: flex
		flex-wrap: wrap
		flex-direction: column
		max-height: 160px
		gap: 24px
		&__item
			color #FFFFFF
			font-size: 16px
			font-weight: 300
			font-family: "JetBrainsMono"
			position relative
			&.active
				color: $brand
			&.arrow
				&:after
					content: ''
					width: 15px
					height: 15px
					display: block
					position: absolute
					bottom: 5px
					right: 25px
					transform: rotate(-90deg)
					background: inline('galka.svg') no-repeat center
					background-size: contain
	&-social
		display: flex
		flex-direction: column
		gap: 24px
		align-items: flex-end
		& a
			display: flex
			flex-direction: row-reverse
			gap: 5px
			align-items: center
			justify-content: center
			text-decoration: none
			font-family: "JetBrainsMono"
			& svg
				width: 16px
				height: 16px
			&.btn
				width: fit-content
				margin-right: -40px

	&__bottom
		display: flex
		justify-content: space-between
		margin-top: 50px
		& p
			font-family: "JetBrainsMono"
			font-size: 12px
			font-weight: 400
			color: $gray
		& a
			font-family: "JetBrainsMono"
			font-size: 12px
			font-weight: 400
			color: $gray
	+media-table-m()
		display none			

