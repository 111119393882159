.about
	padding: 100px 0px
	background: linear-gradient(0deg, rgba(33,33,33,0.9), rgba(33,33,33,0.9)) 0% 0%/ cover
	&__text
		margin-top: 60px
		& p
			font-size: 16px
			font-weight: 300
			line-height: 130%
			font-family: 'JetBrainsMono'
	+media-phone-s()
		padding: 50px 0
		& h2.title
			font-size: 26px
			line-height: 80%