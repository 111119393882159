.cases
	padding: 100px 0px
	min-height: 1200px
	background url('../img/background/Rectangle.svg') no-repeat, linear-gradient(0deg, rgba(33, 33, 33, 0.9), rgba(33, 33, 33, 0.9)) 0% 0% / cover
	& h2.title
		font-weight: 800
		font-size: 38px
	&__container
		margin-top: 150px
	&__row
		display: flex
		gap: 140px
	&__col
		&.--left
			display: flex
			flex-basis: 300px
			& button
				&.btn-dropdown
					display: none
		&.--right
			display: flex
			align-items: center
			justify-content: center
			flex-basis: calc(100% - 300px)
	&-nav
		display: flex
		flex-direction: column
		align-items: flex-start
		&__item
			& + ^[-1]__item
				margin: 30px 0px 0px
			& button
				height: 56px
				padding: 0px 57px
				width: 236px
				display: flex
				justify-content: center
				border-radius: 4px
				border: 0.5px solid #795524
				background: rgba(253, 192, 107, 0.03)
				backdrop-filter: blur(2px)
				align-items: center
				color: $gray
				font-size: 20px
				font-weight: 700
				line-height: 80%
				&[aria-selected="true"]
					background: $brand
					color #171922
				&.startup
					font-size: 18px
	&__content
		display: flex
		flex-direction: column
		gap: 60px
		margin-right: 10px
	&__header
		& h3.title
			position relative
			width: fit-content
			font-size: 38px
			margin: 0px
			&:after
				content: ''
				display: block
				position absolute
				height: 16px
				width: 16px
				background: inline('info-circle.svg') no-repeat center
				background-size: contain
				top: 0
				right -20px
	&__links
		display: flex
		flex-direction: row
		gap: 40px
		& a
			position relative
			font-size: 20px
			font-weight: 700
			color: $gray
			font-family: 'JetBrainsMono'
			text-decoration: none
			&.active
				color: $brand
				&:after
					content: ''
					width: 100%
					height: 2px
					background: $brand
					display: block
					position absolute
				
	&__text
		max-height: 600px
		min-height: 250px
		& p
			&.text
				font-size: 14px
				font-weight: 300
				line-height: 18.2px
				font-family: 'JetBrainsMono'
			&.task
				margin-bottom: 20px
				position relative
				font-size: 20px
				font-weight: 700
				&:before
					content: ''
					display: block
					position absolute
					width: 38px
					height: 50px
					left: -20px
					top: -15px
					background: inline('task.svg') no-repeat center
	&__bottom
		& a
			margin-left: 30px

	+media-table-m()
		background url('../img/background/case_bcg-mob.svg') no-repeat, linear-gradient(0deg, rgba(33, 33, 33, 0.9), rgba(33, 33, 33, 0.9)) 0% 0% / cover
		background-size: cover
		& h2.title
			font-size: 26px
		&__row
			flex-direction: column
			gap: 40px
		&__col
			&.--right
				flex-basis: auto
			&.--left
				flex-basis: auto
				flex-direction: column
				& button
					&.btn-dropdown
						display: block
						height: 56px
						padding: 0px 57px
						display: flex
						justify-content: center
						border-radius: 4px
						border: 0.5px solid #795524
						background: $brand
						color #171922
						backdrop-filter: blur(2px)
						align-items: center
						font-size: 20px
						font-weight: 700
						line-height: 80%
						position relative
						&:before
							content: ''
							width: 12px
							height: 12px
							display: block
							position: absolute
							right: 15%;
							transform: translateX(-50%)
							background: inline('galka_black.svg') no-repeat center
							background-size: contain


		&__container
			margin-top: 50px
		&__header
			& h3.title
				font-size: 26px
		&__content
			gap: 30px
		&__links
			flex-direction: column
			gap: 20px
			& a 
				width: fit-content
		&__text
			max-height: fit-content	
		&-nav
			height 0px
			visibility: hidden
			opacity: 0
			transition: 0.5s
			align-items: center
			margin-top: 30px
			&__item
				width: 100%
				& + .cases-nav__item
					margin: 15px 0 0
				& button
					width: 100%
			&.--show
				height: auto
				visibility: visible
				opacity: 1