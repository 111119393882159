.header
	width: 100%
	max-width: 1920px
	margin-left: auto
	margin-right: auto
	background: linear-gradient(0deg, rgba(33,33,33,0.9), rgba(33,33,33,0.9)) 0% 0%/cover
	&__row
		display: flex
		padding: 20px 45px
		justify-content: space-between
		align-items: center
		flex-shrink: 0
	&__menu
		display: flex
		&-item
			padding-right: 32px
		&-link
			color: $gray
			position relative
			font-size: 16px
			font-family: 'JetBrainsMono'
			font-weight: 300
			&.active
				color: #FFFFFF
				&:before
					content: ''
					width: 12px
					height: 12px
					display: block
					position: absolute
					bottom: 13px
					left: 50%
					transform: translateX(-50%)
					background: inline('galka.svg') no-repeat center
					background-size: contain
	&__phone
		& a
			color: $brand
			text-decoration: none
			font-size: 20px
			font-weight: 600
	&__burger
		display: none
		& button.hamburger
			background: none
			border: none
			& svg
				width: 28px
				height: 28px
	&__col
		&.--mobile
			display: none

	+media-table-m()
		&__row
			padding: 10px 15px
			position relative
		&__phone
			display: none
		&__burger
			display: block
			position: relative
			z-index: 9999
		&__col
			&.--mobile
				margin-left: auto
				display: flex
				justify-content: space-between
				flex-direction: column 
				background: #171922
				width: 40%
				height: 100vh
				// position: absolute
				top: 0px
				left: 0px
				right: 0px
				bottom: 0px
				z-index: 999
				padding: 40px
				transition: 0.5s
				visibility: hidden
				transform: translateX(480px)
				position fixed
				&.active-menu
					transform: translateX(0px)
					visibility: visible
					transition: 0.2 ease
				& > .footer-social
						align-items: flex-start
						& a.btn
							margin-left: 30px
			&.--desctop
				display none
		&__nav
			margin-top: 90px
		&__menu
			flex-direction: column
			&-link
				color: #FFFFFF
				&.active
					color: $brand
					&:before
						left: -15px
						top: 5px
						transform: rotate(-90deg)
			&-item
				margin-bottom: 24px
				padding: 0px
	+media-phone-s()
		&__col
			&.--mobile
				width: 80%