.speak
	padding: 100px 0px
	background: linear-gradient(0deg, rgba(33,33,33,0.9), rgba(33,33,33,0.9)) 0% 0%  
	&__content
		margin: 60px 0px
		& p
			font-family: 'JetBrainsMono'
			&.descr
				font-size: 22px
				font-weight: 300
				color: $gray
	&__row
		display: flex
		flex-wrap: wrap
		margin: -10px
	&__col
		flex-basis: calc((100% / 2) - 20px)
		margin: 10px
		background: inline('solutions.svg') no-repeat
		min-height: 400px
		background-size: 99% 99%
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		text-align: center
		padding: 0 50px
		& p
			font-size: 20px;
			&.name
				color: $brand
				position relative
				&:before
					content: ''
					width: 70px
					height: 1px
					background: #FFFFFF
					position: absolute
					display: block
					top: -10px
					left: 50%
					transform: translateX(-50%)

	+media-phone-s()
		& h2.title
			font-size: 26px
		&__content
			& p.descr
				font-size: 16px
		&__row
			flex-direction: column
		&__col
			flex-basis: calc((100% / 1) - 20px)