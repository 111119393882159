.advantages
	padding: 100px 0px
	background:inline('ellipse.svg') no-repeat, linear-gradient(0deg, rgba(33,33,33,0.9), rgba(33,33,33,0.9)) 0% 0%  
	background-position: left
	background-size: contain
	&__row
		display: flex
		margin: -10px
	&__col
		display: flex
		flex-direction: column
		flex-basis: calc((100% / 3) - 20px)
		margin: 10px
		background: inline('adv.svg') no-repeat
		min-height: 550px
		background-size: contain
		justify-content: center
		text-align: center
		align-items: center
		padding: 70px
		gap: 40px
		& svg
			height: 120px
		&.more_cards
			flex-basis: calc((100% / 4 ) - 20px)
			gap: 24px
			padding: 30px
			min-height: 425px
			& > .advantages__top
				& svg
					height: 90px
			& > .advantages__mid
				& p.title
					font-size: 20px
			& > .advantages__bot
				& p.text
					font-size: 14px
	&__mid
		& p.title
			font-family: 'JetBrainsMono'
			font-size: 24px
			font-weight: 800
			line-height: 24px
			color: $brand
			margin: 0px
	&__bot
		& p.text
			font-family: 'JetBrainsMono'
			font-size: 16px
			font-weight: 300
			line-height: 24px
			margin: 0px
	+media-phone-s()
		padding: 50px 0
		& h2.title
			font-size: 26px
			line-height: 80%
		&__content
			margin-top: 60px
		&__row
			flex-direction: column
		&__col
			flex-basis: calc((100% / 1) - 20px)
			min-height: fit-content
			gap: 20px
			padding: 50px
			justify-content: flex-start
			background-size: 99% 99%
		&__mid
			& p.title
				font-size: 20px
		&__bot
			& p.text
				font-size: 14px