:root
    --color-brand: $brand;

    --black-primary: $light-black-primary;
    --blue-primary: $light-blue-primary;
    
    --gray-primary: $light-gray-primary;
    --gray-secondary: $light-gray-secondary;
    --gray-tertiary: $light-gray-tertiary;
    
    --success-primaty: $light-green-primary;
    --error-primary: $light-red-primary;
    
    --bg-primary: $light-bg-primary;