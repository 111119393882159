.how
	padding: 100px 0px
	min-height: 1000px
	background url('../img/background/RectangleCDP.svg') no-repeat, linear-gradient(0deg, rgba(33, 33, 33, 0.9), rgba(33, 33, 33, 0.9)) 0% 0% / cover
	background-position: center
	&__content
		padding-top: 100px
	&__plates
		margin-top: 170px
	&__row
		display: flex
		margin: -10px
	&__col
		position relative
		display: flex
		flex-direction: column
		flex-basis: calc((100% / 4 ) - 20px)
		// background: inline('cdp_bot.svg') no-repeat
		margin: 10px
		min-height: 310px
		background-size: contain
		justify-content: center
		text-align: center
		align-items: center
		padding: 70px
		gap: 40px
		& svg
			width: 80px
			height: 75px
			margin-right: 40px
		&.--top
			background: inline('cdp_top.svg') no-repeat
			width: 100%
			& p.text
				position absolute
				bottom: 55px
				left: 40px
				text-transform: uppercase
				font-size: 20px
				line-height: 20px
				color: $brand
				font-family: 'JetBrainsMono'
				font-weight: 700
				text-align: left
			& p.descr-top
				position absolute
				width: 290px
				top: -65px
				left: 0px
				text-align: left
				font-family: 'JetBrainsMono'
				font-size: 14px
				font-weight: 300
				line-height: 14px
				&-last
					top: -90px
		&.--bot
			background: inline('cdp_bot.svg') no-repeat
			width: 100%
			& p.text
				position absolute
				bottom: 55px
				left: 75px
				text-transform: uppercase
				font-size: 20px
				line-height: 20px
				color: $brand
				font-family: 'JetBrainsMono'
				font-weight: 700
				text-align: left
			& p.descr
				position absolute
				width: 290px
				bottom: -80px
				left: 30px
				text-align: left
				font-family: 'JetBrainsMono'
				font-size: 14px
				font-weight: 300
				line-height: 14px
			&.--stroke
				&:before
					content: ''
					position absolute
					background: inline('cdp_stroke.svg') no-repeat
					display: block
					height: 100%
					left: 314px
					width: 67px
					top: 35%
		&.--stroke-long
			&:before
				content: ''
				position absolute
				background: inline('cdp_stroke_2.svg') no-repeat
				display: block
				height: 100%
				left: 313px
				width: 67px
		&.--stroke-bot
			&:before
				content: ''
				position absolute
				background: inline('cdp_stroke_bot.svg') no-repeat
				display: block
				height: 100%
				width: 100%
				top: 85%
				left: 60%

	+media-table-m()
		padding: 50px 0px
		&__content
			padding-top: 50px
		& h2.title
			font-size: 26px
			line-height: 80%
		&__row
			flex-direction: column
			gap: 80px
		&__col
			flex-basis: calc((100% / 1) - 20px)
			background-size: contain
			&.--bot
				background: inline('cdp_top.svg') no-repeat
				& p.descr
					top: -75px
					left: 0px
				&.--stroke
					&:before
						display: none
			&.--stroke-bot
				&:before
					display: none
			&.--stroke-long
				&:before
					display: none