// ШРИФТЫ

@font-face 
	font-family: 'Roboto'
	src:
		url(../fonts/RobotoFlex.ttf) format("truetype supports variations"),
		url(../fonts/RobotoFlex.ttf) format("truetype-variations")
	font-weight: 100 1000;
	font-stretch: 25% 151%;
	font-style: normal
@font-face 
	font-family: 'JetBrainsMono'
	src:
		url(../fonts/JetBrainsMono-VariableFont_wght.ttf) format("truetype supports variations"),
		url(../fonts/JetBrainsMono-VariableFont_wght.ttf) format("truetype-variations")
	font-weight: 100 1000;
	font-stretch: 25% 151%;
	font-style: normal