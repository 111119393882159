.top-banner
	background  linear-gradient(0deg, rgba(33, 33, 33, 0.9), rgba(33, 33, 33, 0.9)) 0% 0% / cover, url('../img/background/background.svg')
	&__row
		display: flex
		align-items: center
	&__col
		&.--left
			flex-basis: 600px
		&.--right
			flex-basis: calc(100% - 600px)
		& h1.title
			font-family: 'JetBrainsMono'
			font-size: 32px
			font-weight: 500
			& span
				color: $brand
		& p.descr
			color: $gray
			font-weight: 300
			font-size: 22px
			line-height: 110%
			font-family: 'JetBrainsMono'
		& p.altdescr
			font-size: 16px
			font-family: 'JetBrainsMono'
			font-weight: 300
			margin-top: 30px
			& span
				color: $brand

		& svg
			width: 100%
			height: 700px
		& button
			margin-top: 30px
			margin-left: 30px
	&__scroll
		& a
			display flex
			align-items: center
			text-decoration: none
			gap: 10px
			color: $gray
			font-family: 'JetBrainsMono'
			font-weight: 300
			line-height: 100%
			& svg
				width: 18px
				height: 60px
	+media-table-m()
		&__row
			flex-direction: column-reverse
		&__col
			&.--left
				flex-basis: auto
			&.--right
				flex-basis: auto
				width: 100%
			& svg
				width: 100%
				height: 330px
			& h1.title
				font-size: 24px
			& p.descr
				font-size: 16px
		&__scroll
			display: none