.solutions
	padding: 100px 0px
	background: inline('ellipse_sol.svg') no-repeat, linear-gradient(0deg, rgba(33,33,33,0.9), rgba(33,33,33,0.9)) 0% 0%/ cover
	background-position: center bottom
	background-size: contain
	& h2.title
		font-weight: 800
		font-size: 38px
	&__content
		margin-top: 115px
		position relative
	&__container
		background: inline('solutions.svg') no-repeat
		min-height: 500px
		background-size: contain
		transition: 0.5s ease-in-out
		padding: 75px
	&__text
		& p
			font-family: 'JetBrainsMono'
			font-size: 32px
			color: #FFFFFF
			line-height: 110%
			font-weight: 300
			max-width: 625px
		& button
			margin-left: 30px
			margin-top: 20px
	+media-table-m()
		& h2.title
			font-size: 26px
			width: max-content
	+media-phone-s()
		&__container
			background: inline('vert.svg') no-repeat
			background-size: contain
			align-items: center
			display: flex
			padding: 70px 50px
		&__content
			margin-right: -40px
		

.solutions .splide__slide
	transition: 0.3s linear
	transform: scale(0.7) translateY(250px) translateX(-310px)
	& >.solutions__container
		filter: blur(1.5px)
		& p
			font-family: 'JetBrainsMono'
			font-size: 17px
			color: #FFFFFF
			line-height: 110%
			font-weight: 300
			max-width: 500px
	+media-table-m()
		transform: none

.solutions .splide__slide.is-active
	transform: scale(1)
	min-width: 900px
	transition: 0.3s linear
	position relative
	z-index: 10
	& >.solutions__container
		filter: blur(0px)
		& p
			font-family: 'JetBrainsMono'
			font-size: 32px
			color: #FFFFFF
			line-height: 110%
			font-weight: 300
			max-width: 625px
	+media-phone-s()
		min-width: auto
		align-items: center
		display: flex
		& >.solutions__container
			& p
				font-size: 20px
				width: min-content
.solutions .splide__slide.is-next
	// +media-phone-s()
	// 	margin-left: -50px
.solutions .splide__arrow--prev
	display: none
.solutions .splide__arrow--next
	position absolute
	left:58%
	top: 35%
	z-index 10
	background: transparent
	border: none
	& svg
		width: 80px
		height: 80px
	+media-table-m()
		bottom: 0
		left: 53%
		top: auto
	+media-phone-s()
		bottom: -55px
		left: 35%
		top: auto
